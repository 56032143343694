import React from 'react';

const Pricing = ({ className, children }) => (

	<>
		{/** 
		<section>
			<div className="container flex flex-wrap pt-4 pb-10 m-auto mt-6 md:mt-15 lg:px-12 xl:px-16">
				<div className="w-full px-0 lg:px-4">
					<h2 className="px-12 text-base font-bold text-center md:text-2xl text-blue-700">
						Choose your plan
      </h2>
					<p className="py-1 text-sm text-center text-blue-700 mb-10">
						It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
      </p>
					<div className="flex flex-wrap items-center justify-center py-4 pt-0">
						<div className="w-full p-4 md:w-1/2 lg:w-1/4 plan-card">
							<label className="flex flex-col rounded-lg shadow-lg group relative cursor-pointer hover:shadow-2xl">
								<div className="w-full px-4 py-6 rounded-t-lg card-section-1">
									<h3 className="mx-auto text-base font-semibold text-center underline text-blue-500 group-hover:text-white">
										Standard
              </h3>
									<p className="text-5xl font-bold text-center group-hover:text-white text-blue-500">
										$25.<span className="text-3xl">95</span>
									</p>
									<p className="text-xs text-center uppercase group-hover:text-white text-blue-500">
										monthly
              </p>
								</div>
								<div
									className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-500"
								>
									<p className="text-xl text-white">
										1 month
              </p>
									<button className="w-5/6 py-2 mt-2 font-semibold text-center uppercase bg-white border border-transparent rounded text-blue-500">
										Get Started
              </button>
								</div>
							</label>
						</div>

						<div className="w-full p-4 md:w-1/2 lg:w-1/4">
							<label className="flex flex-col rounded-lg shadow-lg relative cursor-pointer hover:shadow-2xl">
								<div className="w-full px-4 py-8 rounded-t-lg bg-blue-500">
									<h3 className="mx-auto text-base font-semibold text-center underline text-white group-hover:text-white">
										Premium
              </h3>
									<p className="text-5xl font-bold text-center text-white">
										$21.<span className="text-3xl">95</span>
									</p>
									<p className="text-xs text-center uppercase text-white">
										monthly
              </p>
								</div>
								<div
									className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-700"
								>
									<p className="text-xl text-white">
										3 months
              </p>
									<button className="w-5/6 py-2 mt-2 font-semibold text-center uppercase bg-white border border-transparent rounded text-blue-500">
										Save 15%
              </button>
								</div>
							</label>
						</div>

						<div className="w-full p-4 md:w-1/2 lg:w-1/4 plan-card">
							<label className="flex flex-col rounded-lg shadow-lg group card-group relative hover:bg-jblue-secondary cursor-pointer hover:shadow-2xl">
								<div className="w-full px-4 py-6 rounded-t-lg card-section-1">
									<h3 className="mx-auto text-base font-semibold text-center underline text-blue-500 group-hover:text-white">
										Elite
              </h3>
									<p className="text-5xl font-bold text-center group-hover:text-white text-blue-500">
										$19.<span className="text-3xl">45</span>
									</p>
									<p className="text-xs text-center uppercase group-hover:text-white text-blue-500">
										monthly
              </p>
								</div>
								<div
									className="flex flex-col items-center justify-center w-full h-full py-6 rounded-b-lg bg-blue-500"
								>
									<p className="text-xl text-white">
										6 months
              </p>
									<button className="w-5/6 py-2 mt-2 font-semibold text-center uppercase bg-white border border-transparent rounded text-blue-500">
										Save 25%
              </button>
								</div>
							</label>
						</div>

					</div>
				</div>
			</div>
		</section>
		**/}
		<div className="container mx-auto flex items-center">
			<section className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-lg shadow-md flex items-center ">

				<div className="flex-shrink-0 pb-6 space-y-2 border-b">
					<span className="text-3xl font-medium tracking-tight text-green-500 line-through">3€</span>
					<span className="text-gray-400 line-through">/mois</span>
					<span className="flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">Gratuit pendant la beta</span>

				</div>


				<ul className="flex-1 space-y-4">

					<li className="flex items-start">
						<svg className="w-6 h-6 text-green-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
						</svg>
						<span className="ml-3 text-base font-medium" x-text="feature">Vérification de votre identité</span>
					</li>

					<li className="flex items-start">
						<svg className="w-6 h-6 text-green-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
						</svg>
						<span className="ml-3 text-base font-medium" x-text="feature">Partage illimité</span>
					</li>

					<li className="flex items-start">
						<svg className="w-6 h-6 text-green-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
						</svg>
						<span className="ml-3 text-base font-medium" x-text="feature">Contrôle d'accès à vos données</span>
					</li>

					<li className="flex items-start">
						<svg className="w-6 h-6 text-green-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
						</svg>
						<span className="ml-3 text-base font-medium" x-text="feature">Chiffrement et stockages des documents originaux</span>
					</li>

				</ul>

				<div className="flex-shrink-0 pt-4">
					<button className="inline-flex items-center justify-center w-full max-w-xs px-4 py-2 transition-colors border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-500 text-white hover:bg-indigo-700">Inscrivez-vous à la beta fermée</button>
				</div>
			</section>
		</div>
	</>
);

export default Pricing;
