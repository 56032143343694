import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

import Pricing from '../components/Pricing';

const Index = () => (
	<Layout>
		<section className="pt-20 md:pt-40">
			<div className="container mx-auto px-8 lg:flex">
				<div className="text-center lg:text-left lg:w-1/2">
					<h1 className="text-5xl font-bold leading-none">
						Ne divulgez plus vos documents officiels!
          </h1>
					<p className="text-xl lg:text-2xl mt-6 font-light">
						Les faussaires vous demandent vos informations pour s'en servir contre vous.
          </p>
					<p className="mt-8 md:mt-12">
						<Button size="lg">Accédez à la beta fermée</Button>
					</p>
				</div>
				<div className="lg:w-1/2">
					<HeroImage />
				</div>
			</div>
		</section>
		<section id="features" className="py-20 lg:pb-40 lg:pt-48">
			<div className="container mx-auto text-center">
				<h2 className="text-3xl lg:text-5xl font-semibold">Principales fonctionnalités</h2>
				<div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
					<div className="flex-1 px-3">
						<Card className="mb-8">
							<p className="font-semibold text-xl">Stockage de vos documents officiels</p>
							<p className="mt-4">
								Vos documents officiels sont stockés de manière sécurisé et ne sont jamais divulgués. Seule l'information contenue sur le document est divulgué. Voir la section exemple.
              </p>
						</Card>
					</div>
					<div className="flex-1 px-3">
						<Card className="mb-8">
							<p className="font-semibold text-xl">Partage de la juste information</p>
							<p className="mt-4">
								Vos documents officiels contiennent beaucoup trop d'informations que vous souhaitez réellement divulguées. Est-il nécessaire de divulguer son revenu fiscal pour prouver son adresse postale?
              </p>
						</Card>
					</div>
					<div className="flex-1 px-3">
						<Card className="mb-8">
							<p className="font-semibold text-xl">Identification des personnes</p>
							<p className="mt-4">
								Les identités des personnes sont toutes vérifiées. Vous savez exactement à qui vous partager l'information.
              </p>
						</Card>
					</div>
				</div>
			</div>
		</section>
		<SplitSection
			id="services"
			primarySlot={
				<div className="lg:pr-32 xl:pr-48">
					<h3 className="text-3xl font-semibold leading-tight">Analyse des documents</h3>
					<p className="mt-8 text-xl font-light leading-relaxed">
						Pour chaque document officiel, nous procédons à l'extraction des données pertinentes. Vous partagez uniquement ces données
          </p>
				</div>
			}
			secondarySlot={<SvgCharts />}
		/>
		<SplitSection
			reverseOrder
			primarySlot={
				<div className="lg:pl-32 xl:pl-48">
					<h3 className="text-3xl font-semibold leading-tight">
						Transmettez vos documents officiels une seule fois
          </h3>
					<p className="mt-8 text-xl font-light leading-relaxed">

					</p>
				</div>
			}
			secondarySlot={<SvgCharts />}
		/>
		<SplitSection
			primarySlot={
				<div className="lg:pr-32 xl:pr-48">
					<h3 className="text-3xl font-semibold leading-tight">
						Search For Performance Optimization
          </h3>
					<p className="mt-8 text-xl font-light leading-relaxed">
						With all the information in place you will be presented with an action plan that your
						company needs to follow
          </p>
				</div>
			}
			secondarySlot={<SvgCharts />}
		/>
		{/**  
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    **/}
		<section id="testimonials" className="py-20 lg:py-40">
			<div className="container mx-auto">
				<LabelText className="mb-8 text-gray-600 text-center">Quels sont les documents officiels supportés?</LabelText>
				<div className="flex flex-col md:flex-row md:-mx-3">
					{customerData.map(customer => (
						<div key={customer.customerName} className="flex-1 px-3">
							<CustomerCard customer={customer} />
						</div>
					))}
				</div>
				<LabelText className="mb-8 text-gray-600 text-center">D'autres documents sont à venir...</LabelText>
			</div>
		</section>

		<Pricing />
		{/**  
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section>
		**/}
	</Layout>
);

export default Index;
