export default [
  {
    title: "Carte d'identité française",
    content:
      'Ancien et nouveau format',
    customerImage: '/identity_old_fr.jpg'
  },
  {
    title: "Avis d'imposition français",
    content:
      'Toutes les années sont supportées',
    customerImage: '/declaration_2020.jpg'
  },
  {
    title: "Permis de conduire",
    content:
      'Tous les formats supportés',
			customerImage: '/declaration_2020.jpg'
  }
];
